/**
 * ChartJS utilities used by squarespace
 */
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { t } from 'shared/i18n';
import ChartJSCore from '@sqs/chart.js';
import { BlockVariantTypes } from '@sqs/websites-constants';

YUI.add('@sqs/layout-engine/chartjs-helpers', function (Y) {

  Y.namespace('ChartJS');

  var NARROW_CHART_WIDTH = 500;

  var _PageChartBlocks = [];

  var getDefaultChartData = Y.ChartJS.getDefaultChartData = function (type) {
    switch (parseInt(type, 10)) {
      case BlockVariantTypes.CHART_BAR:
        return {
          data: [[150], [250], [150]],
          sampleLabels: [t("New York"), t("Las Vegas"), t("San Francisco")],










          seriesLabels: [t("Population")] };





      case BlockVariantTypes.CHART_LINE:
        return {
          data: [[150, 100], [250, 200], [150, 350]],
          sampleLabels: [t("New York"), t("Las Vegas"), t("San Francisco")],










          seriesLabels: [t("Population"), t("GDP")] };








      case BlockVariantTypes.CHART_PIE:
        return {
          data: [[150], [200], [150]],
          sampleLabels: [t("New York"), t("Las Vegas"), t("San Francisco")],










          seriesLabels: [t("Population")] };}






  };

  function _addPageChartBlock(chartBlock) {
    _PageChartBlocks.push(chartBlock);
  }

  function _remPageChartBlock(chartBlock) {
    _PageChartBlocks.remove(chartBlock);
  }

  function _cleanupChartBlocks() {
    var delChartBlocks = _PageChartBlocks.map(function (block) {
      return block.node.getDOMNode() === null ? block : null;
    });
    delChartBlocks.filter(function (b) {
      return b;
    }).forEach(function (b) {
      b.destroy();
    });
  }

  function _makeColor(pal, i, a) {
    // This is a hard coded palette for now
    var COLORTABLE = {
      'default': [
      [121, 121, 121],
      [191, 191, 191],
      [229, 229, 229],
      [156, 156, 156],
      [0, 0, 0]],

      'Cobblestone': [
      [41, 31, 22],
      [180, 158, 146],
      [127, 112, 105],
      [76, 61, 48],
      [69, 45, 35]],

      'Italia': [
      [124, 184, 200],
      [2, 100, 171],
      [2, 155, 59],
      [227, 2, 27],
      [202, 180, 130]],

      'Muted': [
      [52, 43, 43],
      [112, 105, 100],
      [156, 148, 131],
      [212, 207, 198],
      [240, 226, 208]],

      'Primary': [
      [240, 82, 59],
      [247, 180, 19],
      [36, 128, 196],
      [144, 187, 98],
      [134, 95, 144]],

      'Warm': [
      [153, 0, 0],
      [226, 71, 45],
      [233, 148, 48],
      [248, 220, 171],
      [251, 243, 171]],

      'Cool': [
      [16, 44, 99],
      [7, 75, 230],
      [81, 146, 235],
      [158, 173, 211],
      [225, 220, 252]],

      'Tea Party': [
      [115, 13, 3],
      [182, 125, 44],
      [234, 195, 148],
      [231, 144, 72],
      [64, 2, 18]],

      'Highway': [
      [176, 201, 217],
      [166, 166, 166],
      [89, 72, 57],
      [191, 108, 59],
      [230, 134, 50]],

      'Shops': [
      [227, 227, 230],
      [69, 78, 89],
      [95, 205, 217],
      [113, 153, 138],
      [213, 230, 69]],

      'Suburbs': [
      [73, 33, 48],
      [227, 227, 227],
      [69, 84, 115],
      [206, 208, 159],
      [236, 101, 102]],

      'DB-Nine': [
      [134, 135, 136],
      [168, 182, 111],
      [169, 143, 119],
      [227, 126, 94],
      [100, 138, 189]],

      'M-Three': [
      [98, 140, 187],
      [209, 191, 78],
      [90, 98, 105],
      [13, 76, 136],
      [188, 199, 208]],

      'Thunderbird': [
      [217, 60, 73],
      [103, 189, 218],
      [231, 231, 192],
      [227, 170, 179],
      [177, 202, 123]] };



    var ALPHAVALUES = {
      dark: 1.0,
      medium: 0.75,
      light: 0.5,
      xlight: 0.2,
      off: 0.0 };


    if (!(pal in COLORTABLE)) {
      pal = 'default';
    }

    var palette = COLORTABLE[pal];

    return 'rgba(' + palette[i % palette.length] + ',' + ALPHAVALUES[a] + ')';
  }

  function _makeChartCfg(node) {
    return {
      scaleFontFamily: node.getComputedStyle('font-family'),
      scaleFontSize: parseInt(node.getComputedStyle('font-size'), 10),
      scaleFontStyle: node.getComputedStyle('font-style'),
      tooltipFontFamily: node.getComputedStyle('font-family'),
      tooltipFontSize: parseInt(node.getComputedStyle('font-size'), 10) * 0.8,
      tooltipFontStyle: node.getComputedStyle('font-style'),
      tooltipTitleFontFamily: node.getComputedStyle('font-family'),
      tooltipTitleFontSize: parseInt(node.getComputedStyle('font-size'), 10),
      tooltipTitleFontStyle: 'bold',
      scaleLabel: '<%= \'  \' + value%>' };

  }

  function _getCanvas(node) {
    var canvasNode = node;
    if (canvasNode.get('tagName') !== 'CANVAS') {
      canvasNode = canvasNode.one('canvas');
    }
    return canvasNode;
  }

  function _parseData(d, flip) {

    // var d = {
    //   data: data,
    //   sampleLabels: sampleLabels,
    //   seriesLabels: seriesLabels
    // };

    if (flip) {
      var temp = d.sampleLabels;
      d.sampleLabels = d.seriesLabels;
      d.seriesLabels = temp;
      d.data = Y.Array(d.data[0]).map(function (col, i) {
        return Y.Array(d.data).map(function (row) {
          return row[i];
        });
      });
    }

    var minRows = Math.min(d.data.length, d.sampleLabels.length);
    var minCols = Math.min(d.data[0].length, d.seriesLabels.length);
    d.sampleLabels = d.sampleLabels.slice(0, minRows);
    d.seriesLabels = d.seriesLabels.slice(0, minCols);
    d.data = d.data.slice(0, minRows);
    for (var i = 0; i < d.data.length; i++) {
      d.data[i] = d.data[i].slice(0, minCols);
    }

    return d;
  }

  function _rowsAndColsChanged(chart, dataType, d) {

    var curRows = chart.datasets[0][dataType].length;
    var rowDiff = d.sampleLabels.length - curRows;

    var curCols = chart.datasets.length;
    var colDiff = d.seriesLabels.length - curCols;

    return colDiff || rowDiff && colDiff;
  }

  function _updateChart(chart, dataType, d) {

    // Add/remove cols

    var curCols = chart.datasets.length;
    var colDiff = d.seriesLabels.length - curCols;
    var j = 0;
    if (colDiff > 0) {
      var curDataSet = chart.datasets[curCols - 1];
      for (j = 0; j < colDiff; j++) {
        chart.datasets.push(Y.clone(curDataSet));
      }
    } else if (colDiff < 0) {
      chart.datasets = chart.datasets.slice(0, d.seriesLabels.length);
    }

    // Add/remove rows

    var curRows = chart.datasets[0][dataType].length;
    var rowDiff = d.sampleLabels.length - curRows;

    if (rowDiff > 0) {
      var current = curRows;
      for (j = 0; j < rowDiff; j++) {
        var row = Y.Array(d.seriesLabels).map(function () {
          return 0;
        });
        chart.addData(row, d.sampleLabels[current++]);
      }
    } else if (rowDiff < 0) {
      for (j = 0; j > rowDiff; j--) {
        chart.removeData();
      }
    }

    // Update sample labels
    var i = 0;
    for (i = 0; i < d.sampleLabels.length; i++) {
      chart.scale.xLabels[i] = d.sampleLabels[i];
    }

    // Update values and series labels

    for (i = 0; i < chart.datasets.length; i++) {
      chart.datasets[i].label = d.seriesLabels[i];
      for (j = 0; j < chart.datasets[i][dataType].length; j++) {
        var value = d.data[j][i];
        if (isNaN(value) || value === null || value === undefined) {
          value = 0;
        }
        chart.datasets[i][dataType][j].value = [value];
        chart.datasets[i][dataType][j].label = d.sampleLabels[j];
      }
    }

    chart.update();
  }

  function _mkLegendTemplate(collectionType, colorType) {
    return function (ctx) {
      var html = '';
      html += '<ul class=\"' + Y.Escape.html(ctx.name.toLowerCase()) + '-legend\">';
      for (var i = 0; i < ctx[collectionType].length; i++) {
        html += '<li><span style=\"background-color:' + ctx[collectionType][i][colorType] + '\"></span>';
        if (ctx[collectionType][i].label) {
          html += Y.Escape.html(ctx[collectionType][i].label);
        }
        html += '</li>';
      }
      html += '</ul>';
      return html;
    };
  }

  // -----------------------------------------------------------------------------------
  // Public chartjs API for use by squarespace
  // -----------------------------------------------------------------------------------

  var ChartJSChartBase = function (node, data, palette, flip, options) {//eslint-disable-line max-params
    this.node = node;
    this._options = options || {};
    this._canvas = _getCanvas(node);
    this._narrow = false;
    this._palette = palette;
    this._flip = flip;
    this._data = _parseData(Y.clone(data, true), this._flip);

    this._makeChart();
    this.generateLegend();
    this.resize();

    _addPageChartBlock(this);
  };

  ChartJSChartBase.prototype = {
    destroy: function () {
      this._chart.destroy();
      _remPageChartBlock(this);
    },
    resize: function () {
      var node = this._canvas.getDOMNode();
      if (!node) {
        _cleanupChartBlocks();
      } else if (node.parentNode.clientWidth > 0) {
        this._chart.stop();
        this._chart.resize(this._chart.render, true);
        if (node.parentNode.clientWidth < NARROW_CHART_WIDTH) {
          if (this._chart.name === 'Bar' && !this._narrow) {
            this._options = Y.merge(this._options, {
              barValueSpacing: 5,
              barDatasetSpacing: 1 });

            this._rebuildChart();
          }
          this.node.addClass('narrow-chart');
          this._narrow = true;
        } else {
          if (this._chart.name === 'Bar' && this._narrow) {
            this._options = Y.merge(this._options, {
              barValueSpacing: 20,
              barDatasetSpacing: 5 });

            this._rebuildChart();
          }
          this.node.removeClass('narrow-chart');
          this._narrow = false;
        }
      }
    },
    generateLegend: function () {
      this.node.all('.legend-content').each(function (legend) {
        legend.empty();
        legend.append(this._chart.generateLegend());
      }, this);
    },
    _rebuildChart: function () {
      this._chart.destroy();
      this._makeChart();
    } };


  var ChartJSBarChart = Y.namespace('ChartJS').BarChart =
  Y.extend(function () {
    ChartJSChartBase.apply(this, arguments);
  }, ChartJSChartBase, {

    _makeChart: function () {
      var chartOptions = Y.merge(_makeChartCfg(this.node), {
        barShowStroke: true,
        datasetStrokeWidth: 3,
        animationSteps: 25,
        barValueSpacing: 20,
        barDatasetSpacing: 5,
        legendTemplate: _mkLegendTemplate('datasets', 'fillColor') },
      this._options);

      var chartcfg = {
        labels: this._data.sampleLabels,
        datasets: [] };


      for (var i = 0; i < this._data.seriesLabels.length; i++) {
        var dataset = [];
        for (var j = 0; j < this._data.sampleLabels.length; j++) {
          dataset.push(this._data.data[j][i]);
        }
        chartcfg.datasets.push({
          label: this._data.seriesLabels[i],
          data: dataset,
          fillColor: _makeColor(this._palette, i, 'dark'),
          strokeColor: 'rgba(0,0,0,0)',
          highlightFill: _makeColor(this._palette, i, 'medium'),
          highlightStroke: 'rgba(0,0,0,0)' });

      }

      this._canvas.empty();
      var ctx = this._canvas.getDOMNode().getContext('2d');
      this._chart = new ChartJSCore(ctx).Bar(chartcfg, chartOptions);
    },

    sync: function (data) {
      if (isEmpty(data)) {
        this._data = getDefaultChartData(BlockVariantTypes.CHART_BAR);
      } else {
        this._data = _parseData(Y.clone(data, true), this._flip);
      }
      if (_rowsAndColsChanged(this._chart, 'bars', this._data)) {
        this._rebuildChart();
      } else {
        _updateChart(this._chart, 'bars', this._data);
      }
      this.generateLegend();
      this.resize();
    } });



  var ChartJSLineChart = Y.ChartJS.LineChart =
  Y.extend(function () {
    ChartJSChartBase.apply(this, arguments);
  }, ChartJSChartBase, {

    _makeChart: function () {
      var chartOptions = Y.merge(_makeChartCfg(this.node), {
        datasetFill: false,
        // pointDotRadius: 6,
        // pointDotStrokeWidth: 2.0,
        animationSteps: 25,
        legendTemplate: _mkLegendTemplate('datasets', 'strokeColor') },
      this._options);

      var chartcfg = {
        labels: this._data.sampleLabels,
        datasets: [] };


      for (var i = 0; i < this._data.seriesLabels.length; i++) {
        var dataset = [];
        for (var j = 0; j < this._data.sampleLabels.length; j++) {
          dataset.push(this._data.data[j][i]);
        }
        chartcfg.datasets.push({
          label: this._data.seriesLabels[i],
          data: dataset,
          fillColor: 'rgba(0,0,0,0)',
          strokeColor: _makeColor(this._palette, i, 'dark'),
          pointColor: _makeColor(this._palette, i, 'dark'),
          pointStrokeColor: '#fff',
          pointHighlightFill: '#fff',
          pointHighlightStroke: _makeColor(this._palette, i, 'medium') });

      }

      var ctx = this._canvas.getDOMNode().getContext('2d');
      this._chart = new ChartJSCore(ctx).Line(chartcfg, chartOptions);
    },

    sync: function (data) {
      if (isEmpty(data)) {
        this._data = getDefaultChartData(BlockVariantTypes.CHART_LINE);
      } else {
        this._data = _parseData(Y.clone(data, true), this._flip);
      }
      if (_rowsAndColsChanged(this._chart, 'points', this._data)) {
        this._rebuildChart();
      } else {
        _updateChart(this._chart, 'points', this._data);
      }
      this.generateLegend();
      this.resize();
    } });


  function _allZerosToAllOnes(data) {
    var sum = Y.Array(data.data).reduce(function (init, row) {
      return init + (row.length > 0 ? row[0] : 0);
    }, 0);

    if (sum === 0) {
      data.data = Y.Array(data.data).map(function (row) {
        return Y.Array(row).map(function (cell, index) {
          return index === 0 ? 1 : cell;
        });
      });
    }

    return data;
  }

  var ChartJSPieChart = Y.ChartJS.PieChart =
  Y.extend(function () {
    ChartJSChartBase.apply(this, arguments);
  }, ChartJSChartBase, {

    _makeChart: function () {
      var chartOptions = Y.merge(_makeChartCfg(this.node), {
        datasetFill: false,
        percentageInnerCutout: 60,
        animationSteps: 25,
        legendTemplate: _mkLegendTemplate('segments', 'fillColor') },
      this._options);

      var datasets = [];

      for (var j = 0; j < this._data.sampleLabels.length; j++) {
        datasets.push({
          label: this._data.sampleLabels[j],
          value: this._data.data[j][0],
          color: _makeColor(this._palette, j, 'dark'),
          highlight: _makeColor(this._palette, j, 'medium') });

      }

      var ctx = this._canvas.getDOMNode().getContext('2d');
      this._chart = new ChartJSCore(ctx).Pie(datasets, chartOptions);
    },

    sync: function (data) {
      var j = 0;
      if (isEmpty(data)) {
        this._data = getDefaultChartData(BlockVariantTypes.CHART_PIE);
      } else {
        this._data = _allZerosToAllOnes(_parseData(Y.clone(data, true), this._flip));
      }

      var chart = this._chart;
      var d = this._data;

      // Update samples

      var curRows = chart.segments.length;
      var rowDiff = d.sampleLabels.length - curRows;

      if (rowDiff > 0) {
        var current = curRows;
        for (j = 0; j < rowDiff; j++) {
          chart.addData({
            value: 0,
            label: d.sampleLabels[current],
            color: _makeColor(this._palette, current, 'dark'),
            highlight: _makeColor(this._palette, current, 'medium') });

          current++;
        }
      } else if (rowDiff < 0) {
        for (j = 0; j > rowDiff; j--) {
          chart.removeData();
        }
      }

      // Update sample values

      for (var i = 0; i < chart.segments.length; i++) {
        chart.segments[i].value = d.data[i][0];
        chart.segments[i].label = d.sampleLabels[i];
      }

      chart.update();
      this.generateLegend();
      this.resize();
    } });


  Y.ChartJS.createChart = function (type, node, data, palette, flip) {//eslint-disable-line max-params
    function _getChartCns(ctype) {
      switch (ctype) {
        case BlockVariantTypes.CHART_BAR:
          return ChartJSBarChart;
        case BlockVariantTypes.CHART_LINE:
          return ChartJSLineChart;
        case BlockVariantTypes.CHART_PIE:
          return ChartJSPieChart;}

    }
    var chartType = parseInt(type, 10);
    if (!data || !data.data || data.data.length === 0) {
      data = getDefaultChartData(chartType);
    }
    var chartCns = _getChartCns(chartType);
    var newChart = new chartCns(node, data, palette, flip);
    return newChart;
  };


  // -----------------------------------------------------------------------------------
  // Module initalization and destruction
  // -----------------------------------------------------------------------------------

  var initializeCharts = Y.ChartJS.initializeCharts = function () {
    function hydrateChart(chartBlock) {
      _cleanupChartBlocks();
      var priorNodes = _PageChartBlocks.map(function (c) {
        return c.node;
      });
      var alreadyDone = priorNodes.indexOf(chartBlock) >= 0;
      if (!alreadyDone) {
        // Encoded data has been JSON encoded, and htmlattr encoded (escaped "<>& chars).
        var enc = chartBlock.getAttribute('data-settings') || '{}';
        try {
          // Just decode the &quot; entities, because that will create valid json with html-safe text.
          var dec = JSON.parse(enc.replace(/\&quot;/g, '"'));
          Y.ChartJS.createChart(dec.chartType, chartBlock, dec.dataTable, dec.palette, dec.flip);
        } catch (e) {
          if (e instanceof SyntaxError) {
            // If encoded text included "</script>" it will be encoded as "</scr"+"ipt>" which
            // breaks the JSON parser.
            console.error('[layout-engine/chartjs-helpers] Failed to render chart. Parse error.');
          } else {
            throw e;
          }
        }
      }
    }

    Y.all('.sqs-block.chart-block .chart-block-container').each(hydrateChart);
  };

  var _resizeListenerTimeout;

  function _configureResizeListener() {
    function resizeChartBlocks() {
      clearTimeout(_resizeListenerTimeout);
      _resizeListenerTimeout = setTimeout(function () {
        _PageChartBlocks.forEach(function (chartBlock) {
          chartBlock.resize();
        });
      }, 50);
    }
    Y.one(Y.config.win).after('resize', resizeChartBlocks);
  }

  Y.config.win.Squarespace = Y.config.win.Squarespace || { onInitialize: noop, onDestroy: noop };
  Y.config.win.Squarespace.onInitialize(Y, function () {
    initializeCharts();
    _configureResizeListener();
  });

  Y.config.win.Squarespace.onDestroy(Y, function () {
    clearTimeout(_resizeListenerTimeout);
    _PageChartBlocks.forEach(function (chartBlock) {
      chartBlock.destroy();
    });
  });


}, '1.0', {
  requires: [] });