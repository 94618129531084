// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/chartjs-helpers.css';

/**
 * For CSS loading and rollup the helpers
 */
YUI.add('squarespace-chartjs-helpers', function (Y) {}, '1.0', {
  requires: [
    '@sqs/layout-engine/chartjs-helpers'
  ]
});
